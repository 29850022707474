import { useState } from "react"
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import { Link } from "react-router-dom";
import logo from "../assets/deer-32.png";


export default function Nav() {
    const { logout } = useLogout()
    const { user } = useAuthContext()
    const [showMobileSideBar, setShowMobileSideBar] = useState(false)

    const handleClick = () => {
      logout()
    }
    
    return (
      <header>
        <div>
          <nav className={`lg:flex justify-between px-4 items-center py-3 flex-wrap ${showMobileSideBar ? "border-b" : "border-0"}  lg:border-0`}>
            <div className="lg:flex gap-6 items-center">
             <div className="flex flex-nowrap justify-between items-center">
                <Link to="/" className={`flex p-4 items-center gap-1 text-grey`}>
                  <span className='w-8'><img src={logo} className="w-full"/></span>
                  <span aria-label="Home" className="text-gray-500 visited:text-gray-500 font-bold font-['Arial'] tracking-wide">Sales<span className="text-rose-300">Doe</span></span>
                </Link>
                <div onClick={() => setShowMobileSideBar(!showMobileSideBar)} className="lg:hidden p-4 cursor-pointer">
                    <div className="bg-gray-700 w-8 h-1"></div>
                    <div className="w-8 h-1"></div>
                    <div className="bg-gray-700 w-8 h-1"></div>
                    <div className="w-8 h-1"></div>
                    <div className="bg-gray-700 w-8 h-1"></div>
                </div>
              </div>
              <div className={`lg:flex gap-6 ${showMobileSideBar ? "block" : "hidden"}`}>
                <Link to="/pricing" className="text-center lg:text-left text-gray-500 visited:text-gray-500 block lg:auto py-4 lg:py-0 hover:bg-neutral-100 lg:hover:bg-white">Pricing</Link>
                <Link to="/blog" className="text-center lg:text-left text-gray-500	visited:text-gray-500 block lg:auto py-4 lg:py-0 hover:bg-neutral-100 lg:hover:bg-white">Blog</Link>
              </div>
            </div>

            <div className="lg:flex justify-between gap-6">
  
            {user ?
              <div className={`lg:flex gap-6 lg:px-4 items-center ${showMobileSideBar ? "block" : "hidden"}`}>
                <a className="text-center lg:text-left text-gray-500 block lg:auto py-4 lg:py-0 hover:bg-neutral-100 lg:hover:bg-white" href="/account">Account</a>
                <button onClick={handleClick} className='text-gray-500 lg:px-4 block lg:auto py-4 lg:py-0 hover:bg-neutral-100 lg:hover:bg-white w-full lg:w-auto'>Log out</button>
              </div>
            :
              <div className={`lg:flex gap-6 lg:px-4 items-center ${showMobileSideBar ? "block" : "hidden"}`}>
                <a className="text-center lg:text-left text-gray-500 block lg:auto hover:bg-neutral-100 lg:hover:bg-white py-4 lg:py-0" href="/login">Sign in</a>
                <a className="text-center lg:text-left text-gray-500 border lg:px-4 border-rose-200 hover:text-rose-400 rounded block lg:auto hover:bg-neutral-100 lg:hover:bg-white py-4 lg:py-2" href="/signup">
                  Sign up
                </a>
              </div>
            }
  
            </div>
          </nav>
        </div>
      </header>
    )
  }
