
export default function BioBox() {
    
    return (
      <>
      <hr className="my-12"/>
      <div className="border rounded-md my-4 p-8 border-rose-300">
        <div style={{fontVariant:'small-caps'}} className="pb-2 text-sm text-gray-600">ABOUT THE AUTHOR</div>
        <div className="pt-2 text-rose-500">Dale is the founder of SalesDoe and an experienced digital marketer specializing in E-commerce platforms like Etsy. He enjoys analyzing data and building tools to help other sellers grow their shops successfully.</div>
      </div>
      </>
    )
  }
