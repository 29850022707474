import { createContext, useState, useEffect } from 'react'
import { useAuthContext } from '../hooks/useAuthContext'
import { useLogout } from '../hooks/useLogout'

export const PlanContext = createContext()

export const PlanContextProvider = ({ children }) => {
  const [planData, setPlanData] = useState({
    activePlan: false,
    listingsLimit: 0,
    shopsTrackedLimit: 3,
    shopsTracked: 0,
    kwOverviewLimit: 10,
    kwOverviewCount: 0,
    shopOverviewLimit: 10,
    shopOverviewCount: 0
  })
  const { user } = useAuthContext()
  const { logout } = useLogout()


  const fetchPlanInfo = () => {
    if (user) {
      fetch('/api/billing/context', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'Content-Type': 'application/json'
        }
      }).then(r => {
        return r.json()
      })
        .then(data => {
          const { error, activePlan, listingsLimit, shopsTrackedLimit, shopsTracked, kwOverviewLimit, kwOverviewCount, shopOverviewLimit, shopOverviewCount } = data

          if (error) {
            if (error == "Request is not authorized") {
              logout()
            }
          }

          setPlanData({
            activePlan,
            listingsLimit,
            shopsTrackedLimit,
            shopsTracked,
            kwOverviewLimit,
            kwOverviewCount,
            shopOverviewLimit,
            shopOverviewCount
          })

        })
        .catch(error => console.log(error))
    }

  }

  // get initial state when someone loads a session
  useEffect(() => {
    fetchPlanInfo()
  }, [user])


  return (
    <PlanContext.Provider value={{ ...planData, setPlanData }}>
      {children}
    </PlanContext.Provider>
  )

}