import { Helmet } from "react-helmet"


export default function SEO({ description, title }) {
    return (
      <Helmet
        title={title}
        meta={[
          {
            name: `description`,
            content: description,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: description,
          },
          {
            property: `og:type`,
            content: `website`,
          }
        ]}
      />
    )
  }
  